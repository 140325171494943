/* global adNotam */

adNotam.website.statistics = {
  init: function () {
    $('.territoryTable').click(function () {
      $(this).toggleClass('onlyFirst');
    });

    adNotam.website.statistics.collapseAll = true;
    $('#territory-all').click(function () {
      adNotam.website.statistics.collapseAll
        ? $('.territoryTable').removeClass('onlyFirst')
        : $('.territoryTable').addClass('onlyFirst');
      adNotam.website.statistics.collapseAll = !adNotam.website.statistics.collapseAll;
    });

    $('#getStatistics').click(function () {
      if (
        $('#statistics_type').val() === '' ||
        $('#statistics_end_date').val() === '' ||
        $('#statistics_start_date').val() === ''
      ) {
        alert('Select all fields.');

        return false;
      }
      $.ajax({
        url: '/statistics/country_run_rate',
        type: 'GET',
        data: {
          type: $('#statistics_type').val(),
          start_date: $('#statistics_start_date').val(),
          end_date: $('#statistics_end_date').val()
        }
      })
        .done(function (data) {
          $('.territoryTable').addClass('onlyFirst').hide();
          $('.territoryRow').remove();
          $('#territoryLegend').show();
          $('#chosenTimeframe').html(data.chosen_timeframe);
          $('#previousTimeframe').html(data.previous_timeframe);
          $.each(data.statistics, function (index, value) {
            let arrow =
              value.count < value.previous_count
                ? '<i class="fas fa-arrow-down" style="color:red"></i>'
                : '<i class="fas fa-arrow-up" style="color:green"></i>';

            $('#territory-' + value.territory).show();
            $('#territory-' + value.territory).append(
              '<tr class="territoryRow"><td>' +
                value.country +
                '</td><td>' +
                value.count +
                '</td><td>' +
                value.previous_count +
                '</td><td>' +
                arrow +
                ' ' +
                value.run_rate +
                '%</td></tr>'
            );
          });
        })
        .fail(function (data) {
          alert(data.responseText);
        });
    });
  }
};
