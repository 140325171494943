import { adNotam } from '../../init';

adNotam.website.registerproject = {
  init: function () {
    $('#message_product_line').change(function () {
      if ($('#message_product_line').val() === '') {
        $('#message_series').children().hide();
        $('#message_series').val('reset');
        $('#message_product_line, #message_series').val('');
      } else {
        $('#message_series').children().show().slice(1).remove();

        $.getJSON(
          '/categories/select_values.json?',
          {
            id: $('#message_product_line').val(),
            type: 'product'
          },
          function (data) {
            $.each(data.select_values, function (index, value) {
              $('#message_series').append('<option value=' + value.id + '>' + value.name + '</option>');
            });
          }
        );
      }
    });

    $('#message_series').change(function () {
      if ($('#message_series').val() == 'reset') {
        $('#message_series').val('');
      }
    });

    if ($('#formWithSummary')) {
      adNotam.website.formWithSummary.init();
    }
  }
};
