import { adNotam } from '../../init';

adNotam.website.requestaquote = {
  init: function () {
    this._getSeriesValues();

    $('#message_product_line').on('change', this._getSeriesValues);

    $('#message_series').on('change', function () {
      if ($('#message_series').val() == 'reset') {
        $('#message_model').children().hide();
        $('#message_model').val('reset');
        $('#message_series, #message_model').val('');
      } else {
        $('#message_model').children().show().slice(1).remove();

        $.getJSON(
          '/categories/select_values.json?',
          {
            id: $('#message_series').val(),
            type: 'product'
          },
          function (data) {
            $.each(data.select_values, function (index, value) {
              $('#message_model').append('<option value=' + value.id + '>' + value.name + '</option>');
            });

            const required = $('#message_model').children().length > 1;

            $('#message_model').toggleClass('required', required).prop('required', required);
            $('label[for="message_model"]').toggleClass('required', required);
            $('label[for="message_model"]').find('abbr').toggle(required);
          }
        );
      }
    });

    $('#message_model').on('change', function () {
      if ($('#message_model').val() == 'reset') {
        $('#message_model').val('');
      }
    });

    if ($('#formWithSummary')) {
      adNotam.website.formWithSummary.init();
    }
  },

  _getSeriesValues: function () {
    if ($('#message_product_line').val() === '') {
      $('#message_series, #message_model').children().hide();
      $('#message_series').val('reset');
      $('#message_model').val('reset');
      $('#message_product_line, #message_series, #message_model').val('');
    } else {
      $('#message_series').children().show().slice(1).remove();
      $('#message_model').children().hide().slice(1).remove();

      $.getJSON(
        '/categories/select_values.json?',
        {
          id: $('#message_product_line').val(),
          type: 'product'
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#message_series').append('<option value=' + value.id + '>' + value.name + '</option>');
          });
        }
      );
    }
  }
};
