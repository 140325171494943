import { adNotam } from '../../init';

adNotam.website.manualsdocumentation = {
  init: function () {
    $('#selectSeries').children().slice(1).hide();
    $('#selectModel').children().slice(1).hide();

    $('#selectProduct').change(function () {
      $('#yourDocSelection').hide();
      $('#problemWarning').hide();
      if ($('#selectProduct').val() == 'reset') {
        $('#selectSeries').children().slice(1).hide();
        $('#selectSeries').val('reset');
        $('#selectModel').val('reset');
      } else {
        $('#selectSeries').children().show().slice(1).remove();
      }
      $('#selectModel').children().slice(1).hide().remove();

      $.getJSON(
        '/categories/select_values.json?',
        {
          id: $('#selectProduct').val(),
          type: $('#selectProduct option:selected').data('type')
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#selectSeries').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
          });
        }
      );
    });

    $('#selectSeries').change(function () {
      $('#yourDocSelection').hide();
      $('#problemWarning').hide();
      if ($('#selectSeries').val() == 'reset') {
        $('#selectModel').children().slice(1).hide();
        $('#selectModel').val('reset');
      } else {
        $('#selectModel').children().show().slice(1).remove();

        $.getJSON(
          '/categories/select_values.json?',
          {
            id: $('#selectSeries').val(),
            type: $('#selectSeries option:selected').data('type')
          },
          function (data) {
            $.each(data.select_values, function (index, value) {
              $('#selectModel').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
            });
          }
        );
      }
    });

    $('#selectModel').change(function () {
      $('#yourDocSelectionContent').children().slice(1).remove();
      $('#problemWarning').hide();

      $.getJSON(
        '/attachments/files.json?',
        {
          id: $('#selectModel').val(),
          type: $('#selectModel option:selected').data('type')
        },
        function (data) {
          if (jQuery.isEmptyObject(data.files)) {
            $('#yourDocSelection').hide();
            $('#problemWarning').show();
          } else {
            $.each(data.files, function (index, value) {
              let doc;

              if (index == 0) {
                doc = $('.oneDocSelection').first();
              } else {
                doc = $('.oneDocSelection').first().clone();
              }
              doc.children('.selectionType').text(value.type);
              doc.children('.selectionName').text(value.name);
              doc.children('.selectionInfo').text(value.size + ', ' + value.mime_type + ', ' + value.language);
              doc.children('.selectionDate').text(value.date);
              doc.find('.downloadLink').attr('href', value.url);
              doc.appendTo('#yourDocSelectionContent');
            });
            $('#problemWarning').hide();
            $('#yourDocSelection').show();
          }
        }
      );
    });
  }
};
