import { adNotam } from '../../init';

adNotam.website.slider = {
  init: function () {
    if (!$('.carousel').length) {
      return;
    }

    const mqMax450 = window.matchMedia('only screen and (max-width: 450px)');

    let params = location.search.substring(1).split('=');

    if (params[0] == 'slide') {
      let slideIndex = parseInt(params[1]);

      if (!isNaN(slideIndex) && slideIndex > 0 && slideIndex <= $('.carousel-item').length) {
        $('.carousel').carousel(slideIndex - 1);
      }
    }

    if (!mqMax450.matches) {
      adNotam.website.slider.updateText($('.carousel').find('.carousel-item.active'));
    }

    $('.carousel').on('slide.bs.carousel', function (e) {
      if (!mqMax450.matches) {
        adNotam.website.slider.updateText($(e.relatedTarget));
      }
    });
  },

  updateText: function (slide) {
    let data = $('img', slide).data();

    if (Object.keys(data).length > 0) {
      $('.carouselInfoRow').hide();

      if (data.description) {
        $('#description-info').html(data.description);
        $('#description-info').fadeOut(300, function () {
          $('.carouselTransBox').fadeIn();
          $('#description-info').fadeIn(500);
        });
      } else {
        for (let key in data) {
          $('#' + key + '-info')
            .text(data[key])
            .parent()
            .show();
        }

        $('.carouselInfoRowSecondCol').fadeOut(300, function () {
          $('.carouselTransBox').fadeIn();
          $('.carouselInfoRowSecondCol').fadeIn(500);
        });
      }
    } else {
      $('.carouselTransBox').fadeOut(150);
    }
  }
};
