import { adNotam } from '../../init';

adNotam.website.menus = {
  init: function () {
    this.main_menu.init();
    this.sidebar_menu.init();
  },

  main_menu: {
    init: function () {
      this._setActivePage(adNotam.common.currentPageID);

      $('.primaryNav').smartmenus({
        subMenusSubOffsetY: -1, // pixels offset from default position (default: 0)--------------
        subIndicatorsText: '', // [optionally] add text in the SPAN (e.g. '+') (you may want to check the CSS for the sub indicators too) (default: +)-------------
        showTimeout: 150, // timeout before showing the sub menus (default: 250)------------------------
        hideTimeout: 100 // timeout before hiding the sub menus (default: 500)-----------------------
      });
    },

    _setActivePage: function (pageID) {
      if (typeof pageID !== 'undefined') {
        $('#siteNavigation a').each(function (i, a) {
          let $a = $(a);

          if ($a.data('page-id') === pageID) {
            $a.addClass('active').parentsUntil('#siteNavigation', 'li').addClass('active');
          }
        });
      }
    }
  },

  sidebar_menu: {
    init: function () {
      this._setActivePage(adNotam.common.currentPageID);
    },

    _setActivePage: function (pageID) {
      $('#sidebarNav a').each(function (i, a) {
        let $a = $(a);

        if ($a.data('page-id') === pageID || $a.attr('href') === window.location.pathname + window.location.hash) {
          $a.addClass('active').parentsUntil('#sidebarNav', 'li').addClass('active');
        }
      });
    }
  }
};
