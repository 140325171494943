import { adNotam } from '../../init';

adNotam.website.content = {
  init: function () {
    if ($('.carousel')) {
      adNotam.website.slider.init();
    }

    if ($('.infounit').length > 0) {
      adNotam.website.infounit.init();
    }

    // If we need to sort tables
    // function sortTable(){
    //   var rows = $('#ourClients tbody  tr').get();

    //   rows.sort(function(a, b) {

    //   var A = $(a).children('td').eq(2).text().toUpperCase();
    //   var B = $(b).children('td').eq(2).text().toUpperCase();

    //   if(A < B) {
    //     return -1;
    //   }

    //   if(A > B) {
    //     return 1;
    //   }

    //   return 0;

    //   });

    //   $.each(rows, function(index, row) {
    //     $('#ourClients').children('tbody').append(row);
    //   });
    // }
  }
};
