(function ($) {
  $.fn.autolist = function (opts) {
    let $this = $(this);
    let index = null;

    let defaults = {
      rowSelector: 'li',
      rowTemplateStart: '<div class="autolist-row" style="display:none;">',
      rowTemplateEnd: '</div>',
      elementSelector: 'input, select, textarea',
      deletionClass: 'delete',
      callbackDelete: null,
      callbackAfterDelete: null,
      callbackAdd: null
    };

    $.extend(defaults, opts);

    $this.on('change', function () {
      let empty_rows = [];

      $this.find(defaults.rowSelector).each(function () {
        let empty = true;
        let row = $(this);

        $(this)
          .find(defaults.elementSelector)
          .each(function () {
            if ($(this).val()) {
              row.find('.' + defaults.deletionClass).show();
              empty = false;
            }
          });

        if (empty) {
          empty_rows.push(this);
        }
      });

      let last_empty = true;
      let last_row = $this.find(defaults.rowSelector + ':last');

      last_row.find(defaults.elementSelector).each(function () {
        if ($(this).val()) {
          last_empty = false;
        }
      });

      let last = empty_rows.length;

      if (empty_rows[last - 1] == last_row.get(0)) {
        last -= 1;
      }

      for (let i = 0; i < last; ++i) {
        if (defaults.callbackDelete) {
          defaults.callbackDelete.call(empty_rows[i]);
        }

        $(empty_rows[i]).fadeOut('fast', function () {
          index = $(this).data('index');
          $(this).remove();

          if (defaults.callbackAfterDelete) {
            defaults.callbackAfterDelete.call(this);
          }
        });
      }

      if (!last_empty) {
        index = last_row.data('index');

        $this.append(defaults.rowTemplateStart + last_row.html() + defaults.rowTemplateEnd);

        last_row = $this.find(defaults.rowSelector + ':last');
        last_row.find(defaults.elementSelector).val('');
        last_row.find('.' + defaults.deletionClass).hide();

        $('[id]', last_row).each(function () {
          $(this).attr('id', $(this).attr('id').slice(0, $(this).attr('id').lastIndexOf(index)));
          $(this).attr('id', $(this).attr('id') + (index + 1));
        });
        $('input, select, textarea', last_row).each(function () {
          $(this).attr(
            'name',
            $(this)
              .attr('name')
              .replace('[' + index + ']', '[' + (index + 1) + ']')
          );
        });
        last_row.data('index', index + 1);

        if (defaults.callbackAdd) {
          defaults.callbackAdd.call(last_row);
        }

        last_row.fadeIn('fast');
      }
    });

    $this.on('click', function (ev) {
      let $trg = $(ev.target);

      if ($trg.hasClass(defaults.deletionClass)) {
        let $cgrp = $trg.closest(defaults.rowSelector);
        let html = $cgrp.html();

        if (defaults.callbackDelete) {
          defaults.callbackDelete.call($cgrp.get(0));
        }

        $cgrp.fadeOut('fast', function () {
          index = $(this).data('index');
          $(this).remove();

          if (defaults.callbackAfterDelete) {
            defaults.callbackAfterDelete.call(this);
          }

          let last;

          if ($this.find(defaults.rowSelector).length == 0) {
            $this.append(defaults.rowTemplateStart + html + defaults.rowTemplateEnd);

            last = $this.find(defaults.rowSelector + ':last');
            last.find(defaults.elementSelector).val('');

            $('[id]', last).each(function () {
              $(this).attr('id', $(this).attr('id').slice(0, $(this).attr('id').lastIndexOf(index)));
              $(this).attr('id', $(this).attr('id') + 0);
            });
            $('input, select, textarea', last).each(function () {
              $(this).attr(
                'name',
                $(this)
                  .attr('name')
                  .replace('[' + index + ']', '[0]')
              );
            });
            last.data('index', 0);
            index = 0;

            if (defaults.callbackAdd) {
              defaults.callbackAdd.call(last);
            }

            last.fadeIn('fast');
          } else {
            let all_empty = 0;

            $this.find(defaults.rowSelector).each(function () {
              let is_empty = true;

              $(this)
                .find(defaults.elementSelector)
                .each(function () {
                  if ($(this).val()) {
                    is_empty = false;
                  }
                });

              if (is_empty) {
                ++all_empty;
              }
            });

            if (all_empty == 0) {
              $this.append(defaults.rowTemplateStart + html + defaults.rowTemplateEnd);

              last = $this.find(defaults.rowSelector + ':last');
              last.data('index', index);
              last.find(defaults.elementSelector).val('');

              if (defaults.callbackAdd) {
                defaults.callbackAdd.call(last);
              }

              last.fadeIn('fast');
            }
          }
        });
      }
    });
  };
})(jQuery);
