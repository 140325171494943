import { adNotam } from '../../init';

adNotam.website.materialrequest = {
  init: function () {
    $('#requestForm').submit(function () {
      let checked_values = $('input:checkbox:checked')
        .map(function () {
          return this.value;
        })
        .get();

      if (checked_values.length > 0) {
        let text = 'The user requested following material:\n';

        text += checked_values.join('\n');
        $('#message_message').val(text);
      } else {
        return false;
      }
    });
  }
};
