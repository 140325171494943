import { adNotam } from '../../init';

adNotam.website.registerextendedwarranty = {
  init: function () {
    $('#selectSeries').children().slice(1).hide();
    $('#selectModel').children().slice(1).hide();

    $('#extendedWarrantyForm').validate({
      ignore: '',
      highlight: function (element) {
        if ($(element).attr('name') == 'message[extendend_warranty_months]') {
          $('.customRadioButton').addClass('error');
        } else {
          $(element).addClass('error');
        }
      },
      unhighlight: function (element) {
        if ($(element).attr('name') == 'message[extendend_warranty_months]') {
          $('.customRadioButton').removeClass('error');
        } else {
          $(element).removeClass('error');
        }
      }
    });

    $('#extendedWarrantyForm').submit(function () {
      if ($('#selectProduct').val() == 'reset' || $('#selectProduct').val() == null) {
        $('#selectProduct').addClass('error');
      }

      if ($('#selectSeries').val() == 'reset' || $('#selectSeries').val() == null) {
        $('#selectSeries').addClass('error');
      }

      if ($('#selectModel').val() == 'reset' || $('#selectModel').val() == null) {
        $('#selectModel').addClass('error');
      }

      if ($('#extendedWarrantyForm').valid()) {
        $('#extendedWarrantyForm').submit();
      } else {
        return false;
      }
    });

    $('#selectProduct').change(function () {
      $('#yourDocSelection').hide();
      $('#problemWarning').hide();
      if ($('#selectProduct').val() == 'reset') {
        $('#selectSeries').children().slice(1).hide();
        $('#selectModel').children().slice(1).hide();
        $('#selectSeries').val('reset');
        $('#selectModel').val('reset');
        $('#message_product, #message_series, #message_model').val('');
        $('#selectProduct').addClass('error');
      } else {
        $('#selectSeries').children().show().slice(1).remove();
        $('#message_product').val($('#selectProduct option:selected').text());
        $('#selectModel').children().slice(1).hide().remove();
        $('#selectProduct').removeClass('error');

        $.getJSON(
          '/categories/select_values.json?',
          {
            id: $('#selectProduct').val(),
            type: $('#selectProduct option:selected').data('type')
          },
          function (data) {
            $.each(data.select_values, function (index, value) {
              $('#selectSeries').append(
                '<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>'
              );
            });
          }
        );
      }
    });

    $('#selectSeries').change(function () {
      $('#yourDocSelection').hide();
      $('#problemWarning').hide();
      if ($('#selectSeries').val() == 'reset') {
        $('#selectModel').children().slice(1).hide();
        $('#selectModel').val('reset');
        $('#message_series, #message_model').val('');
        $('#selectSeries').addClass('error');
      } else {
        $('#selectModel').children().show().slice(1).remove();
        $('#message_series').val($('#selectSeries option:selected').text());
        $('#selectSeries').removeClass('error');

        $.getJSON(
          '/categories/select_values.json?',
          {
            id: $('#selectSeries').val(),
            type: $('#selectSeries option:selected').data('type')
          },
          function (data) {
            $.each(data.select_values, function (index, value) {
              $('#selectModel').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
            });
          }
        );
      }
    });

    $('#selectModel').change(function () {
      if ($('#selectModel').val() == 'reset') {
        $('#selectModel').addClass('error');
        $('#message_model').val('');
      } else {
        $('#selectModel').removeClass('error');
        $('#message_model').val($('#selectModel option:selected').text());
      }
    });
  }
};
