import { adNotam } from '../../init';

adNotam.website.screenportfolio = {
  init: function () {
    if (window.location.hash.length) {
      $('#screenSizePortfolioSelect').val(window.location.hash);
    }

    $('#screenSizePortfolioSelect')
      .on('change', function () {
        let wantedDiv = $('#screenSizePortfolioSelect').val();

        $('div.screenSizeInfos .display_info').hide();
        $(wantedDiv).fadeIn('slow');
      })
      .trigger('change');
  }
};
