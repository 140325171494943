/* global adNotam, I18n */

adNotam.website.picgallery = {
  init: function () {
    let self = this;

    self.currentPage = 1;
    self.totalPages = 1;
    self.pictures = [];
    self.currentImage = 1;
    self.thumbNumber = $('.gallery_image').length;

    if ($('.selectContent').length) {
      adNotam.website.picgallery.getPictures($('#selectProduct').val(), $('#selectSegment').val(), $('#selectApplication').val());
      $('.selectContent').change(function () {
        self.currentPage = 1;
        adNotam.website.picgallery.getPictures(
          $('#selectProduct').val(),
          $('#selectSegment').val(),
          $('#selectApplication').val()
        );
      });
    }

    $('.pagination .previous').click(function () {
      self.currentPage == 1 ? (self.currentPage = self.totalPages) : self.currentPage--;
      adNotam.website.picgallery.updateThumbnails();
    });

    $('.pagination .next').click(function () {
      self.currentPage == self.totalPages ? (self.currentPage = 1) : self.currentPage++;
      adNotam.website.picgallery.updateThumbnails();
    });

    $('.gallery_image a').click(function () {
      self.currentImage = parseInt($(this).data('id'));
      $('.paginationText').magnificPopup('open');
    });
  },

  getPictures: function (product, segment, application) {
    let self = this;

    $.getJSON(
      '/gallery/products.json?',
      {
        product,
        segment,
        application
      },
      function (data) {
        if ($('.selectContent').length) {
          $('.selectContent').each(function () {
            $(this).children().slice(1).remove();
          });
          $.each(data.products, function (index, value) {
            $('#selectProduct').append('<option value=' + value[0] + '>' + value[1] + '</option>');
          });
          $.each(data.segments, function (index, value) {
            $('#selectSegment').append('<option value=' + value[0] + '>' + value[1] + '</option>');
          });
          $.each(data.applications, function (index, value) {
            $('#selectApplication').append('<option value=' + value[0] + '>' + value[1] + '</option>');
          });
          $('.selectContent').each(function () {
            if ($(this).children().length == 2) $(this).children().eq(1).attr('selected', true);
          });
        }

        self.pictures = data.pictures;
        let product = I18n.t('activerecord.attributes.picture_attribute.category');
        let segment = I18n.t('activerecord.attributes.picture_attribute.segment');
        let application = I18n.t('activerecord.attributes.picture_attribute.application');
        let screenSize = I18n.t('activerecord.attributes.picture_attribute.screen');

        $('.paginationText').magnificPopup({
          items: self.pictures,
          type: 'image',
          gallery: {
            enabled: true,
            preload: [0, 2] // Will preload 0 - before current, and 2 after the current image
          },
          image: {
            markup:
              '<figure class="galleryPopup">' +
              '<span class="image-container">' +
              '<div class="mfp-img"></div>' +
              '<div class="mfp-close"></div>' +
              '</span>' +
              '<figcaption class="imgInfoGalleryPopup">' +
              '<div class="captionSection">' +
              '<p>' +
              product +
              '</p>' +
              '<p class="mfp-categoryname"></p>' +
              '</div>' +
              '<div class="captionSection">' +
              '<p>' +
              segment +
              '</p>' +
              '<p class="mfp-segmentname"></p>' +
              '</div>' +
              '<div class="captionSection">' +
              '<p>' +
              application +
              '</p>' +
              '<p class="mfp-applicationname"></p>' +
              '</div>' +
              '<div class="captionSection" id="captionScreensize">' +
              '<p>' +
              screenSize +
              '</p>' +
              '<p class="mfp-screensize"></p>' +
              '</div>' +
              '</figcaption>' +
              '</figure>'
          },
          callbacks: {
            beforeOpen: function () {
              $.magnificPopup.instance.goTo(self.currentImage);
            },
            open: function () {
              if ($('.mfp-screensize').is(':empty')) {
                $('#captionScreensize').hide();
              } else {
                $('.mfp-screensize').text(parseFloat(self.pictures[this.index].screensize).toFixed(1));
              }
            },
            change: function () {
              let itemPage = Math.floor(this.index / self.thumbNumber) + 1;

              if (self.currentPage != itemPage) {
                self.currentPage = itemPage;
                adNotam.website.picgallery.updateThumbnails();
              }
            },
            afterChange: function () {
              if ($('.mfp-screensize').is(':empty')) {
                $('#captionScreensize').hide();
              } else {
                $('.mfp-screensize').text(parseFloat(self.pictures[this.index].screensize).toFixed(1));
                $('#captionScreensize').show();
              }
            }
          }
        });
        adNotam.website.picgallery.updateThumbnails();
      }
    );
  },

  updateThumbnails: function () {
    let self = this;
    let preloadImages = Array();
    let startIndex = (self.currentPage - 1) * self.thumbNumber;

    self.totalPages = Math.ceil(self.pictures.length / self.thumbNumber);
    $('.paginationText').text(I18n.t('views.pagination.page') + ' ' + self.currentPage + '/' + self.totalPages);
    $('.gallery_image img').removeAttr('src');

    for (let i = startIndex + self.thumbNumber; i < startIndex + 2 * self.thumbNumber; i++) {
      if (self.pictures[i]) {
        preloadImages.push(self.pictures[i].thumbnail_url);
      } else {
        break;
      }
    }

    $('.gallery_image').each(function (i) {
      if (self.pictures[startIndex + i]) {
        $(this).addClass('loading').show();
        let image = new Image();

        image.onload = function () {
          let currentImage = $('.gallery_image').eq(i);

          $('img', currentImage).attr('src', image.src);
          $('a', currentImage).data('id', startIndex + i);
          currentImage.removeClass('loading');
        };
        image.src = self.pictures[startIndex + i].thumbnail_url;
        preloadImages.push(self.pictures[startIndex + i].src);
      } else {
        $(this).hide();
      }
    });
    adNotam.website.picgallery.preload(preloadImages);
  },

  preload: function (images) {
    $(images).each(function () {
      new Image().src = this;
    });
  }
};
