// -----------------------------------------------------------
// -------------- Technical Support Page ---------------------
// -----------------------------------------------------------

/* global adNotam, I18n */

adNotam.website.technicalsupport = {
  init: function () {
    // To make the tabs of the technical support not clickable
    $('a[href="#problem"]').click(function () {
      return false;
    });
    $('a[href="#email"]').click(function () {
      return false;
    });
    // The behaviour of the buttons "btn-back" and "btn-next-js"
    $('.tab-content .btn-next-js.signed-in').click(function () {
      $('#problemWarning').hide();
      $('#email-tab').tab('show');
      $('.sidebarbox').show();
    });
    $('.tab-content .btn-back').click(function () {
      $('#problem-tab').tab('show');
      $('.sidebarbox').hide();
    });

    $('#selectSeries').children().slice(1).hide();
    $('#selectModel').children().slice(1).hide();
    $('.sidebarbox').hide();
    $('.faqList, #firstTabNav').hide();

    $('#selectProduct').change(function () {
      if ($('#selectProduct').val() == 'reset') {
        $('#selectSeries').children().slice(1).hide();
        $('#seriesSelect').children().slice(1).hide();
        $('#selectSeries, #seriesSelect').val('reset');
        $('#selectModel, #modelSelect').val('reset');
        if ($('#selectIssue').val() == 'reset' || $('#selectIssue').val() === undefined) {
          $('.faqList, #firstTabNav').hide();
        }
      } else {
        $('#selectSeries').children().show().slice(1).remove();
      }
      $('#selectModel').children().slice(1).hide().remove();
      $('#productSelect').val($('#selectProduct').val());

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id: $('#selectProduct').val(),
          type: $('#selectProduct option:selected').data('type'),
          issue: $('#selectIssue').val()
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#selectSeries').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
            $('#seriesSelect').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
          });
          updateFaqs(data.faqs);
          updateIssues(data.issues);
        }
      );
    });

    $('#productSelect').change(function () {
      if ($('#productSelect').val() == 'reset') {
        $('#seriesSelect').children().slice(1).hide();
        $('#seriesSelect').val('reset');
        $('#modelSelect').val('reset');
      } else {
        $('#seriesSelect').children().show().slice(1).remove();
      }
      $('#modelSelect').children().slice(1).hide().remove();

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id: $('#productSelect').val(),
          type: $('#productSelect option:selected').data('type'),
          issue: $('#selectIssue').val()
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#seriesSelect').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
          });
        }
      );
    });

    $('#selectSeries').change(function () {
      let id, type;

      if ($('#selectSeries').val() == 'reset') {
        $('#selectModel, #modelSelect').children().slice(1).hide();
        $('#selectModel, #modelSelect').val('reset');
        id = $('#selectProduct').val();
        type = $('#selectProduct option:selected').data('type');
      } else {
        $('#selectModel').children().show().slice(1).remove();
        id = $('#selectSeries').val();
        type = $('#selectSeries option:selected').data('type');
      }
      $('#seriesSelect').val($('#selectSeries').val());

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id,
          type,
          issue: $('#selectIssue').val()
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#selectModel').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
            $('#modelSelect').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
          });
          if ($('#selectSeries').val() == 'reset') {
            $('#selectModel').children().slice(1).hide();
          }
          updateFaqs(data.faqs);
          updateIssues(data.issues);
        }
      );
    });

    $('#seriesSelect').change(function () {
      let id, type;

      if ($('#seriesSelect').val() == 'reset') {
        $('#modelSelect').children().slice(1).hide();
        $('#modelSelect').val('reset');
        id = $('#productSelect').val();
        type = $('#productSelect option:selected').data('type');
      } else {
        $('#modelSelect').children().show().slice(1).remove();
        id = $('#seriesSelect').val();
        type = $('#seriesSelect option:selected').data('type');
      }

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id,
          type,
          issue: $('#selectIssue').val()
        },
        function (data) {
          $.each(data.select_values, function (index, value) {
            $('#modelSelect').append('<option value=' + value.id + ' data-type=' + value.type + '>' + value.name + '</option>');
          });
          if ($('#seriesSelect').val() == 'reset') {
            $('#modelSelect').children().slice(1).hide();
          }
        }
      );
    });

    $('#selectModel').change(function () {
      let id, type;

      if ($('#selectModel').val() == 'reset') {
        id = $('#selectSeries').val();
        type = $('#selectSeries option:selected').data('type');
      } else {
        id = $('#selectModel').val();
        type = $('#selectModel option:selected').data('type');
      }
      $('#modelSelect').val($('#selectModel').val());

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id,
          type,
          issue: $('#selectIssue').val()
        },
        function (data) {
          updateFaqs(data.faqs);
          updateIssues(data.issues);
        }
      );
    });

    $('#selectIssue').change(function () {
      let id, type;

      if ($('#selectModel').val() != null && $('#selectModel').val() != 'reset') {
        id = $('#selectModel').val();
        type = $('#selectModel option:selected').data('type');
      } else if ($('#selectSeries').val() != null && $('#selectSeries').val() != 'reset') {
        id = $('#selectSeries').val();
        type = $('#selectSeries option:selected').data('type');
      } else if ($('#selectProduct').val() != null && $('#selectProduct').val() != 'reset') {
        id = $('#selectProduct').val();
        type = $('#selectProduct option:selected').data('type');
      } else {
        id = 'reset';
        type = 'reset';
        $('.faqList, #firstTabNav').hide();
      }
      $('#issueSelect').val($('#selectIssue').val());

      $.getJSON(
        '/faqs/get_faqs.json?',
        {
          id,
          type,
          issue: $('#selectIssue').val()
        },
        function (data) {
          updateFaqs(data.faqs);
        }
      );
    });

    $('#fileupload').change(function () {
      let files = $('#fileupload').get(0).files;

      if (files.length < 1) {
        $('#fileuploadText').text(I18n.t('js.messages.select_files'));
      } else if (files.length == 1) {
        $('#fileuploadText').text(files.item(0).name);
      } else if (files.length < 6) {
        $('#fileuploadText').text(files.length + ' ' + I18n.t('js.messages.files_selected'));
      } else {
        $('#fileupload').val('');
        $('#fileuploadText').text(I18n.t('js.messages.maximum_five_files'));
      }

      for (let i = 0; i < files.length; i++) {
        if (files.item(i).size > 10485760) {
          $('#fileupload').val('');
          $('#fileuploadText').text(I18n.t('js.messages.file_too_big'));
        }
      }
    });

    $('#supportForm').validate({
      highlight: function (element) {
        if ($(element).attr('name') == 'message[message]') {
          $(element).parent().addClass('error');
        } else {
          $(element).addClass('error');
        }
        $('.fillRequiredFields').show();
      },
      unhighlight: function (element) {
        if ($(element).attr('name') == 'message[message]') {
          $(element).parent().removeClass('error');
        } else {
          $(element).removeClass('error');
        }

        if ($('#supportForm').find('.error').length == 0) {
          $('.fillRequiredFields').hide();
        }
      }
    });

    $('.buttonFormSubmit-js').click(function () {
      if ($('#supportForm').valid()) {
        $('#message_product').val($('#productSelect option:selected').text());
        $('#message_series').val($('#seriesSelect option:selected').text());
        $('#message_model').val($('#modelSelect option:selected').text());
        $('#message_issue').val($('#issueSelect option:selected').text());

        $('.buttonFormSubmit-js').attr('disabled', true);
        $('#supportForm').submit();
      }
    });

    function updateFaqs(faqs) {
      $('.faq').hide();
      if (faqs.length == 0) {
        $('.faqList').hide();
        $('#problemWarning, #firstTabNav').show();
      } else if (
        ($('#selectProduct').val() == 'reset' || $('#selectProduct').val() === undefined) &&
        ($('#selectIssue').val() == 'reset' || $('#selectIssue').val() === undefined)
      ) {
        $('.faqList, #problemWarning').hide();
      } else {
        $('.faqList, #firstTabNav').show();
        $('#problemWarning').hide();
        $.each(faqs, function (index, value) {
          $('#faq' + value)
            .parent()
            .show();
        });
      }
    }

    function updateIssues(issues) {
      let selectedIssue = $('#selectIssue').val();

      $('#selectIssue').children().slice(1).remove();
      $.each(issues, function (index, value) {
        $('#selectIssue').append('<option value=' + value.id + '>' + value.name + '</option>');
      });
      $('#selectIssue').val(selectedIssue);
    }
  }
};
