/* global adNotam */

adNotam.users.registrations = {
  init: function () {
    // Check if we have the element formWithSummary
    if ($('#formWithSummary')) {
      adNotam.website.formWithSummary.init();
    }
  }
};
