/* eslint no-console:0 */
/* eslint-disable react-hooks/rules-of-hooks */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'modernizr';
import 'jquery-ujs';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-de';
import 'jquery-ui/ui/i18n/datepicker-en-GB';
import 'jquery-ui/ui/i18n/datepicker-es';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/ui/i18n/datepicker-it';
import 'jquery-ui/ui/i18n/datepicker-pt';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-validation';
import 'bootstrap';
import 'doc-cookies';
import 'magnific-popup';
import 'mediaelement';
import 'jquery.ellipsis/jquery.ellipsis';
import 'jquery-lazyload';
import 'jquery-simplecolorpicker/jquery.simplecolorpicker';
import 'smartmenus/dist/jquery.smartmenus';
import 'inflection-js/inflection';

import './src/init.js';

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./src/jquery/', true, /\.js$|\.mjs$/));
importAll(require.context('./src/website/', true, /\.js$|\.mjs$/));
require('./src/polyfills.js');
