/* init.js
 *
 *  It handles the execution of js scripts
 *
 */

/* global docCookies, Modernizr */

import { i18n } from './i18n/config';
import ahoy from 'ahoy.js';

export const adNotam = {
  init: function () {
    let body = document.body,
      layout = body.getAttribute('data-layout'),
      controller = body.getAttribute('data-controller'),
      action = body.getAttribute('data-action');

    adNotam.utils.exec(['common']);

    if (layout) {
      adNotam.utils.exec(['website', 'common']);
      adNotam.utils.exec(['website', layout]);
      adNotam.utils.exec(['website', layout], 'afterAction');
    } else {
      let pieces = controller.split('/');

      controller = [];
      for (let i = 0; i < pieces.length; ++i) {
        if (pieces[i] === '') {
          continue;
        }
        controller.push(pieces[i]);
      }

      if (controller.length > 0) {
        adNotam.utils.exec([controller[0], 'common']);
      }

      adNotam.utils.exec(controller);
      adNotam.utils.exec(controller, action);
      adNotam.utils.exec(controller, 'afterAction');
    }
    adNotam.utils.exec(['common'], 'last');
  },

  utils: {
    exec: function (sections, action) {
      let ns = adNotam;

      action = action === undefined ? 'init' : action;

      if (sections.length > 0) {
        for (let i = 0; i < sections.length; ++i) {
          if (!ns[sections[i]]) {
            return;
          }

          ns = ns[sections[i]];
        }

        if (typeof ns[action] == 'function') {
          ns[action]();
        }
      }
    }
  },

  common: {
    init: function () {
      // first function executed on all pages
      $('input[type=url]').on('change', function () {
        let val = $(this).val();

        if (val !== '' && !val.match(/^https?:\/\//)) {
          $(this).val('http://' + val);
        }
      });

      if (!Modernizr.inputtypes.date) {
        $('input[type=date]').datepicker({
          dateFormat: 'yy-mm-dd'
        });
      }

      ahoy.configure({ cookies: false });

      $("<li class='logoInNav'></li>").prependTo($('.primaryNav'));
      $('.logoInNav').append($('#logo'));

      let mqMax980 = window.matchMedia('only screen and (max-width: 980px)');
      let mqMax915 = window.matchMedia('only screen and (max-width: 915px)');
      let mqMin751Max915 = window.matchMedia('only screen and (min-width: 751px) and (max-width: 915px)');
      let mqMax750 = window.matchMedia('only screen and (max-width: 750px)');

      if (matchMedia) {
        mqMax750.addListener(WidthChangeNavDesktop_Mobile);
        mqMax915.addListener(CheckScrollToCutSidebar);
        WidthChangeNavDesktop_Mobile(mqMax750);
        CheckScrollToCutSidebar(mqMax915);
      }

      this.currentPageID =
        typeof $('body').data('parent-page-id') == 'undefined' ? $('body').data('page-id') : $('body').data('parent-page-id');
      adNotam.website.menus.init();

      // ********************* Top banner *********************************
      if (!docCookies.hasItem('geoOk')) {
        $('#geoInfoBox').show();
      }

      if (!docCookies.hasItem('cookiesOk')) {
        $('body').css('margin-bottom', $('#cookiesInfoBox').innerHeight());
        $('#cookiesInfoBox').show();
      }

      $('#geoNext').on('click', function (ev) {
        ev.preventDefault();
        docCookies.setItem('geoOk', 'true', Infinity, '/', null, null, 'lax');
        $('#geoInfoBox').hide();
      });

      $('#cookiesNext').on('click', function (ev) {
        ev.preventDefault();
        docCookies.setItem('cookiesOk', 'true', Infinity, '/', null, null, 'lax');
        $('#cookiesInfoBox').hide();
        $('body').css('margin-bottom', 0);
      });
      // **********************************************************************

      // ********************* Side-navigation able to hide *****************************
      $('.showSideNav').on('click', function () {
        $(this).parents('div#sidebarAndContentBar').toggleClass('active');
        $(this).parents('aside#sidebar').toggleClass('sidebarActive');
        $(this).toggleClass('active');
        $(this).parents('aside#sidebar').scrollTop(0);
      });
      // ********************************************************************************

      // ********************* Main navigation for mobile *****************************
      let $menu = $('#siteNavigation'),
        $logoAndNavigation = $('#logoAndNavigation'),
        $menulink = $('.mobileMenuLink'),
        $menuTrigger = $('li:has(ul)').children('a');

      $menuTrigger.addClass('has-submenu');
      $menulink.on('click', function (e) {
        e.preventDefault();
        $menulink.toggleClass('active');
        $menu.toggleClass('active');
        $logoAndNavigation.toggleClass('active');
        $('.loginSelectCountry').toggleClass('hidden');
        $('.logoMobile').toggleClass('active');
        $('#siteNavigation').find('.activeMobile').removeClass('activeMobile');
      });
      $menuTrigger.on('click', function (e) {
        if (mqMax750.matches) {
          e.preventDefault();
        }
        let $this = $(this);

        $this.toggleClass('activeMobile').next('ul').toggleClass('activeMobile');
      });
      // ******************************************************************************

      // ********************* WidthChangeNavDesktop_Mobile: To manage the changes between big and little devices according to the width of the screen *****************************
      function WidthChangeNavDesktop_Mobile(mq) {
        if (mq.matches) {
          $('.sm.sm-simple.primaryNav').removeAttr('class'); // For small devices smartmenus is not going to be used
          $('#siteNavigation li').on(
            'mouseenter.smartmenus mouseleave.smartmenus mousedown.smartmenus focus.smartmenus blur.smartmenus',
            'a',
            function (e) {
              e.stopPropagation();
              e.preventDefault();
            }
          );
          $('#siteNavigation ul').removeAttr('style');
          $('body[data-layout="newsoverview"] .piclinks:not(.prominent) .piclink').each(function () {
            $(this).find('.newsDate').clone().appendTo($(this).find('a'));
            $(this).find('a').append('<p></p>');
            $(this).find('a p').text($(this).find('h1').text());
          });
          $('body[data-layout="movies"] .piclinks .piclink').each(function () {
            $(this).find('div').append("<p class='videoInfo1'></p>");
            $(this).find('div').append("<p class='videoInfo2'></p>");
            $(this).find('div').append("<p class='videoInfo3'></p>");
            $(this).find('div .videoInfo1').text($(this).find('.transBox h1').text());
            $(this).find('div .videoInfo2').text($(this).find('.transBox h2').text());
            $(this).find('div .videoInfo3').text($(this).find('.transBox p').text());
          });
          $('#logoAndNavigation').addClass('logoAndNavigation-mobile');
          $('#logo').clone().attr({ class: 'logoMobile', id: 'logoMobile' }).insertBefore($('.loginSelectCountry'));
          $('#siteNavigation').addClass('mobileMenu');
          $('.loginSelectCountry').addClass('hidden');
        } else {
          $('#siteNavigation > ul').addClass('sm sm-simple primaryNav');
          $('.primaryNav').smartmenus({
            subMenusSubOffsetY: -1, // pixels offset from default position (default: 0)--------------
            subIndicatorsText: '', // [optionally] add text in the SPAN (e.g. '+') (you may want to check the CSS for the sub indicators too) (default: +)-------------
            showTimeout: 150, // timeout before showing the sub menus (default: 250)------------------------
            hideTimeout: 100 // timeout before hiding the sub menus (default: 500)-----------------------
          });
          $('#siteNavigation li').off(
            'mouseenter.smartmenus mouseleave.smartmenus mousedown.smartmenus focus.smartmenus blur.smartmenus'
          );
          $('#siteNavigation ul.sm-nowrap').attr('style', 'display: none');
          $('body[data-layout="newsoverview"] .piclinks:not(.prominent) .piclink').each(function () {
            $(this).find('a .newsDate').remove();
            $(this).find('a p').remove();
          });
          $('body[data-layout="movies"] .piclinks .piclink').each(function () {
            $(this).find('div .videoInfo1').remove();
            $(this).find('div .videoInfo2').remove();
            $(this).find('div .videoInfo3').remove();
          });
          $('#logoAndNavigation').removeClass('logoAndNavigation-mobile');
          $('header .logoMobile').remove();
          $('#siteNavigation').removeClass('mobileMenu');
          $('.loginSelectCountry').removeClass('hidden');
          // Remove class active. If you go wider than mobile and then you go again to mobile width the menu will be closed
          $('.mobileMenuLink').removeClass('active');
          $('#siteNavigation').removeClass('active');
          $('#logoAndNavigation').removeClass('active');
          $('#siteNavigation').find('.activeMobile').removeClass('activeMobile');
        }
      }
      // *********************************************************************************************************************************************************************

      // ********************* Avoid that smartmenus are used in small devices *****************************
      if (mqMax750.matches) {
        $('.sm.sm-simple.primaryNav').removeAttr('class'); // For small devices smartmenus is not going to be used
      } else {
        $('.primaryNav').smartmenus({
          subMenusSubOffsetY: -1, // pixels offset from default position (default: 0)--------------
          subIndicatorsText: '', // [optionally] add text in the SPAN (e.g. '+') (you may want to check the CSS for the sub indicators too) (default: +)-------------
          showTimeout: 150, // timeout before showing the sub menus (default: 250)------------------------
          hideTimeout: 100 // timeout before hiding the sub menus (default: 500)-----------------------
        });
      }
      // ***************************************************************************************************

      // ********************* Modernizr custom test *********************************
      Modernizr.testStyles(
        '#modernizr{font:0/0 a}#modernizr:after{content:":)";visibility:hidden;font:7px/1 a; line-height: 5rem}',
        function (node) {
          Modernizr.addTest('pseudoelementlineheightinrems', node.offsetHeight >= 10);
        }
      );
      // **********************************************************************

      // ********************* To fix the sidebar *****************************
      let sidebarHeight = $('#sidebarNav').height();
      let contentBottomOffset = 210;

      $(window).on('scroll', function () {
        let contentLength = $(document).height() - sidebarHeight - contentBottomOffset;
        let scrollTop = $(window).scrollTop();

        if (!$('#sidebar').hasClass('configurator')) {
          if ($('#sidebar').hasClass('startPage')) {
            if ($('#sidebarAndContentBar').length && scrollTop <= $('#sidebarAndContentBar').offset().top - 7) {
              $('#sidebarAndContentBar').removeClass('scrolled noMainNav');
            } else {
              $('#sidebarAndContentBar').addClass('scrolled');
            }
          } else if (mqMax750.matches) {
            if ($('#sidebarAndContentBar').length && scrollTop <= $('#sidebarAndContentBar').offset().top + 15) {
              $('#sidebarAndContentBar').removeClass('scrolled noMainNav');
            } else {
              $('#sidebarAndContentBar').addClass('scrolled');
            }
          } else if (mqMax980.matches) {
            // If width < 981px, the sidebar-nav don't use the class "bottom" and the offset is smaller
            if ($(window).height() > 450) {
              if ($('#sidebarAndContentBar').length && scrollTop <= $('#sidebarAndContentBar').offset().top - 70) {
                $('#sidebarAndContentBar').removeClass('scrolled noMainNav');
              } else {
                $('#sidebarAndContentBar').addClass('scrolled');
              }
            } else {
              // Special case (normally landscape phablets) where the viewport is wide enough for the 2rows navi but not enough high to show satisfyingly fixed navi and content simultaneously
              if ($('#sidebarAndContentBar').length && scrollTop <= $('#sidebarAndContentBar').offset().top + 15) {
                $('#sidebarAndContentBar').removeClass('scrolled noMainNav');
              } else {
                $('#sidebarAndContentBar').addClass('scrolled noMainNav');
              }
            }
          } else if ($('#sidebarAndContentBar').length && $('#sidebarNav').height() < $('.contentbar').height()) {
            contentLength = $(document).height() - $('#sidebarNav').height() - contentBottomOffset;
            if (scrollTop <= $('#sidebarAndContentBar').offset().top - 64) {
              $('#sidebarAndContentBar').removeClass('bottom').removeClass('scrolled noMainNav');
            } else if (scrollTop > contentLength) {
              $('#sidebarAndContentBar').removeClass('scrolled noMainNav').addClass('bottom');
            } else {
              $('#sidebarAndContentBar').removeClass('bottom').addClass('scrolled noMainNav');
            }
          }
        }
      });
      // *********************************************************************+*****************************
      // ********************* To avoid that the sidebar covers the footer *****************************
      function CheckScrollToCutSidebar(mq) {
        $(window).on('scroll', function () {
          if (mq.matches) {
            let footerHeight = $('footer').height();
            let pctgWithoutFooter;

            if (mqMax750.matches) {
              pctgWithoutFooter = 100 - Math.floor((footerHeight / $(window).height()) * 100);
            } else {
              let navigationHeight = $('#logoAndNavigation').height();
              let pctgNavigation = Math.floor((navigationHeight / $(window).height()) * 100);

              if (mqMin751Max915.matches && $(window).height() > 450) {
                pctgWithoutFooter = 100 - pctgNavigation - Math.floor((footerHeight / $(window).height()) * 100);
              } else {
                pctgWithoutFooter = 100 - Math.floor((footerHeight / $(window).height()) * 100);
              }
            }

            if ($(window).scrollTop() + $(window).height() > $(document).height() - footerHeight) {
              $('#sidebarAndContentBar.scrolled #sidebar').css('max-height', pctgWithoutFooter + 'vh');
            } else {
              $('#sidebarAndContentBar.scrolled #sidebar').css('max-height', '98vh');
            }
          } else {
            $('#sidebarAndContentBar.scrolled #sidebar').removeAttr('style');
          }
        });
      }
      // ***********************************************************************************************

      // Modals
      adNotam.website.modals.init();
    }
  },

  website: {
    common: {
      init: function () {
        let msgs = i18n.t('configurations.js.validator');

        for (let key in msgs) {
          if (msgs[key].substr(0, 2) == '$.') {
            msgs[key] = eval(msgs[key]);
          }
        }

        $.extend($.validator.messages, msgs);

        // ********************* Main navigation gets fixed if scrolled *****************************
        $(window).on('scroll', function () {
          if (
            $(this).scrollTop() > 10 &&
            (mqMax750.matches || mqMin981.matches || mqMin751Max980.matches) &&
            $(window).height() > 450
          ) {
            $('header').addClass('scrolled');
            $('#content').addClass('content-scrolled');
          } else {
            $('header').removeClass('scrolled');
            $('#content').removeClass('content-scrolled');
          }
        });
        // *******************************************************************************************************

        // ********************* MEDIA QUERIES & NAVIGATION *****************************

        // ********************* Media queries variables *****************************
        const mqMax1260 = window.matchMedia('only screen and (max-width: 1260px)');
        const mqMin981 = window.matchMedia('only screen and (min-width: 981px)');

        const mqMax980 = window.matchMedia('only screen and (max-width: 980px)');
        const mqMin791 = window.matchMedia('only screen and (min-width: 791px)');
        const mqMax790 = window.matchMedia('only screen and (max-width: 790px)');
        const mqMax750 = window.matchMedia('only screen and (max-width: 750px)');

        const mqMax686 = window.matchMedia('only screen and (max-width: 686px)');
        const mqMax620 = window.matchMedia('only screen and (max-width: 620px)');
        const mqMax600 = window.matchMedia('only screen and (max-width: 600px)');
        const mqMin751Max980 = window.matchMedia('only screen and (min-width: 751px) and (max-width: 980px)');

        const mqMax480 = window.matchMedia('only screen and (max-width: 480px)');
        const mqMax450 = window.matchMedia('only screen and (max-width: 450px)');
        const mqMax350 = window.matchMedia('only screen and (max-width: 350px)');
        // ***************************************************************************

        // ********************* Media query event handler *****************************
        if (matchMedia) {
          mqMax600.addListener(WidthChangeInfounits);
          mqMax750.addListener(WidthChangeTable);
          mqMax686.addListener(ResponsivePicGalleryFilterInstruction);
          mqMin791.addListener(MyadnotamProductTableAllCols);
          mqMax790.addListener(MyadnotamProductTable3Cols);
          mqMax620.addListener(MyadnotamProductTable2Cols);
          mqMax450.addListener(MyadnotamProductTable1Col);
          mqMax480.addListener(ProductButtonsResp);
          WidthChangeInfounits(mqMax600);
          WidthChangeTable(mqMax750);
          ResponsivePicGalleryFilterInstruction(mqMax686);
          MyadnotamProductTableAllCols(mqMin791);
          MyadnotamProductTable3Cols(mqMax790);
          MyadnotamProductTable2Cols(mqMax620);
          MyadnotamProductTable1Col(mqMax450);
          ProductButtonsResp(mqMax480);
        }

        function WidthChangeInfounits(mq) {
          if (mq.matches) {
            $('.infounit').each(function () {
              $(this).find('p').first().before($(this).find('.imageContainer'));
            });
          } else {
            $('.infounit').each(function () {
              $(this).find('.textContainer').after($(this).find('.imageContainer'));
            });
          }
        }

        // ********************* ProductButtonsResp: To manage the changes in the .productButtons according to the width of the screen *****************************
        function ProductButtonsResp(mq) {
          if (mq.matches) {
            $('.productButtons').insertBefore($('.productFirstInfos table'));
          } else {
            $('.productButtons').insertAfter($('.productFirstInfos table'));
          }
        }
        // *********************************************************************************************************************************************************

        // ************************** jquery.ellipsis ******************************
        $('.prominent .transBox h1').ellipsis({ lines: 1 });
        $('.transBox .mainContentActualNews').ellipsis();
        $('.SubheaderActualNews').ellipsis({ lines: 1 });
        if (mqMax350.matches) {
          $('.transBox .mainContentLandingPages').ellipsis({ lines: 1 });
        } else if (mqMax450.matches) {
          $('.transBox .mainContentLandingPages').ellipsis({ lines: 2 });
        } else if (mqMax980.matches) {
          $('.transBox .mainContentLandingPages').ellipsis({ lines: 1 });
        } else {
          $('.transBox .mainContentLandingPages').ellipsis({ lines: 2 });
        }

        if (mqMax1260.matches) {
          $('body[data-layout="newsoverview"] .piclinks:not(.prominent) .piclink .transBox h1').ellipsis({ lines: 2 });
        }

        if (mqMax750.matches) {
          $('body[data-layout="newsoverview"] .piclinks:not(.prominent) .piclink a p').ellipsis({ lines: 2 });
        }
        // **********************************************************************

        // ********************* Responsive tables *****************************
        function WidthChangeTable(mq) {
          if (mq.matches) {
            $(
              '.infounit + table, .tablePageWith2Cols, .tablePageWith3Cols, .tablePageWith4Cols, .tablePageWith5Cols, .tablePageWith6Cols, .freetext table'
            ).each(function () {
              if ($(this).find('tbody tr').length == 1) {
                $(this).addClass('onlyOneRow');
              }
              $(this)
                .find('thead th')
                .each(function (thIndex) {
                  let $this = $(this);
                  let ToClone = $this.children().first();
                  let thText = $this.text();

                  $this
                    .parents('table')
                    .find('tbody tr')
                    .each(function () {
                      if (thText !== '') {
                        if (ToClone.prop('tagName') == 'P') {
                          ToClone.clone().attr('class', 'p-tableRWD').prependTo($(this).find('td').eq(thIndex));
                        } else {
                          $("<p class='p-tableRWD'></p>").text('').prependTo($(this).find('td').eq(thIndex));
                          if (typeof ToClone.prop('tagName') === 'undefined') {
                            $(this).find('td .p-tableRWD').eq(thIndex).text(thText);
                          } else {
                            ToClone.clone().prependTo($(this).find('td .p-tableRWD').eq(thIndex));
                          }
                        }
                      }
                    });
                });
            });
            $('caption').replaceWith(function () {
              return "<h1 class='tableCaption'>" + $(this).html() + '</h1>';
            });
          } else {
            $('.p-tableRWD').remove();
            $('.tableCaption').replaceWith(function () {
              return '<caption>' + $(this).html() + '</caption>';
            });
          }
        }

        // ********************* SliceTable *****************************
        //  table2Slice: Table to split in "slices"
        //  columnInSlice: Number of columns for every "slice"
        //
        //  From one big table it makes tables that are "slices" of the big one. You can specify the number of columns of the "slices"
        // **************************************************************
        function SliceTable(table2Slice, columnInSlice) {
          // if columnInSlice == 0, it makes nothing, it shows the table as it is.
          if (columnInSlice != 0) {
            let tableColNumber = table2Slice.find('tbody tr').first().find('td').length;
            let slicesNumber;

            if (tableColNumber % columnInSlice > 0) {
              slicesNumber = Math.floor(tableColNumber / columnInSlice) + 1;
            } else {
              slicesNumber = tableColNumber / columnInSlice;
            }

            for (let i = 0; i < slicesNumber; i++) {
              table2Slice.clone().addClass('currentSlice Slice').insertBefore(table2Slice); // The whole original table is cloned
              let SliceBegin = i * columnInSlice;
              let SliceEnd;

              if (SliceBegin + columnInSlice > tableColNumber) {
                SliceEnd = tableColNumber;
              } else {
                SliceEnd = SliceBegin + columnInSlice;
              }
              CutCurrentSlice($('.currentSlice'), tableColNumber, SliceBegin, SliceEnd); // Here, the columns that we don't need for the "slice" get hidden
              $('.currentSlice').removeClass('currentSlice');
            }
            table2Slice.addClass('origToSliceAndHide');
          }
        }

        // ********************* CutCurrentSlice *****************************
        //  table2Slice: Table to be changed
        //  totalTableColNumber: Number of columns of the original table
        //  SliceBegin: Begin of the "slice"
        //  SliceEnd: End of the "slice"
        //
        //  From one big table it makes tables that are "slices" of the big one. You can specify the number of columns of the "slices"
        // **************************************************************
        function CutCurrentSlice(table2Slice, totalTableColNumber, SliceBegin, SliceEnd) {
          if (SliceEnd - SliceBegin == 1) {
            // Special case with only one column
            table2Slice.addClass('singleColumn');
            table2Slice.find('tbody tr').each(function () {
              $(this).find("p:contains('')").remove(); // Empty paragraphs get removed
              if ($(this).find('td').eq(SliceBegin).children().length == 0) {
                $(this).remove(); // Also empty cells get removed
              } else {
                $(this).find('.tableSection').clone().prependTo($(this).find('td').eq(SliceBegin)); // .tableSection gets inside of the cell
              }
            });
          }
          table2Slice.find('thead tr').each(function () {
            // The cells that are not needed for the slice get hidden
            for (let i = 1; i < totalTableColNumber + 1; i++) {
              // always +1 because the first colum of the header is always an empty column.
              if (i < SliceBegin + 1 || i >= SliceEnd + 1) {
                $(this).find('th').eq(i).hide();
              }
            }
          });
          table2Slice.find('tbody tr').each(function () {
            // The cells that are not needed for the slice get hidden
            for (let i = 0; i < totalTableColNumber; i++) {
              if (i < SliceBegin || i >= SliceEnd) {
                $(this).find('td').eq(i).hide();
              }
            }
          });
        }

        function MyadnotamProductTable3Cols(mq) {
          if (mq.matches) {
            SliceTable($('.myadnotamProductTable'), 3);
          } else if (mqMin791.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 0);
          }
        }

        function MyadnotamProductTable2Cols(mq) {
          if (mq.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 2);
          } else if (mqMax790.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 3);
          }
        }

        function MyadnotamProductTable1Col(mq) {
          if (mq.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 1);
          } else if (mqMax620.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 2);
          }
        }

        function MyadnotamProductTableAllCols(mq) {
          if (mq.matches) {
            $('.myadnotamProductTable.Slice').remove();
            $('.origToSliceAndHide').removeClass('origToSliceAndHide');
            SliceTable($('.myadnotamProductTable'), 0);
          }
        }

        // ***************************************************************************************

        // ********************* Responsive picgallery *****************************
        function ResponsivePicGalleryFilterInstruction(mq) {
          if (mq.matches) {
            $('#filterInstruction br').remove();
          }
        }

        // *************************************************************************

        // ********************* Class .dateOfBirth for the legend of the .dateOfBirthSelects div's *****************************
        $('.dateOfBirthSelects').each(function () {
          $(this).parent().find('legend').addClass('dateOfBirth');
        });

        // **********************************************************************************************************************

        // ********************* End of MEDIA QUERIES & NAVIGATION *****************************

        if ($(document).height() < 1000) {
          $('.buttonTopOfPage').hide();
        }

        let url = window.location.pathname;

        $('body').scrollspy({ target: '#sidebar', offset: 108 });

        // Find all anchors
        $('#sidebarNav')
          .find('a[href]')
          .each(function (i, a) {
            let $a = $(a);
            let href = $a.attr('href');

            // check if anchor href starts with page's path
            if (href.indexOf(url + '#') == 0) {
              // remove path from href
              href = href.replace(url, '');
              // update anchors href with new one
              $a.attr('href', href);
            }
            // Now refresh scrollspy
            $('body').scrollspy('refresh');
          });

        // ********************* Button top of page *****************************
        $('.buttonTopOfPage').click(function (event) {
          event.preventDefault();
          $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
        });
        // **********************************************************************

        $(window).scrollTop($(window).scrollTop() - 1);
        $(window).scrollTop($(window).scrollTop() + 1);

        adNotam.website.slider.init();
      }
    }
  },

  users: {},

  configurator: {
    common: {
      init: function () {
        const action = document.body.getAttribute('data-action');

        // Disable configurator for small devices, but not the configurations table
        let mqMax750 = window.matchMedia('only screen and (max-width: 750px)');

        if (matchMedia) {
          if (mqMax750.matches && action !== 'index' && action !== 'archive') {
            let alert_message = '<p>' + i18n.t('global.modals.small_devices_alert') + '</p>';

            alert_message += '<br /><a href="/" class="btn btn-secondary btn-back">' + i18n.t('global.home') + '</a>';
            adNotam.configurator.utils.info(alert_message, true);
            $('.modal-backdrop').css('background-color', '#ddd');
            $('.modal-backdrop').css('opacity', '1');
            adNotam.configurator.enabled = false;

            return;
          } else {
            adNotam.configurator.enabled = true;
          }
        }

        $('.pagination .disabled a, .pagination .active a').on('click', function (ev) {
          ev.preventDefault();
        });
      }
    },
    components: {},
    errors: {},
    utils: {
      components: {}
    }
  }
};

$(adNotam.init);
