import { adNotam } from '../../init';

adNotam.website.infounit = {
  init: function () {
    $('.infounit a.magicMirrorBtnOn').click(function () {
      $(this).addClass('magicMirrorBtnActive');
      $(this).parent().children('a.magicMirrorBtnOff').removeClass('magicMirrorBtnActive');
      $(this).parents('div.infounit').find('img.magicMirrorPicOn').fadeIn('slow');
    });

    $('.infounit a.magicMirrorBtnOff').click(function () {
      $(this).addClass('magicMirrorBtnActive');
      $(this).parent().children('a.magicMirrorBtnOn').removeClass('magicMirrorBtnActive');
      $(this).parents('div.infounit').find('img.magicMirrorPicOn').fadeOut('slow');
    });
  }
};
