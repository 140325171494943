import { adNotam } from '../../init';
import ahoy from 'ahoy.js';

adNotam.website.product = {
  init: function () {
    if ($('.productvideos')) {
      $('video').mediaelementplayer();
      $('video').one('play', function () {
        ahoy.track('alchemy/attachments', {
          action: 'play',
          label: window.location.pathname,
          value: $(this).attr('data-id')
        });
      });
    }
  }
};
