import { adNotam } from '../../init';
import { i18n } from '../../i18n/config';

adNotam.website.modals = {
  init: function () {
    // Confirm modals
    $(document).on('confirm', function (event) {
      if (!event.target.click) {
        return true;
      }

      let $element = $(event.target);
      let message = $element.data('confirm').replace(/\n/g, '<br />');
      let $confirmModal = $('#genericModal').clone().attr('id', 'confirmModal');
      let $cancelButton = $('<a></a>').text(i18n.t('global.no')).addClass('btn btn-secondary').attr('data-dismiss', 'modal');

      $confirmModal.on('hidden.bs.modal', function (e) {
        $(e.target).remove();
      });

      let $okButton = $('<a></a>')
        .text(I18n.t('global.yes'))
        .addClass('btn btn-danger')
        .on({
          click: function () {
            $element.data('confirm', null);
            $confirmModal.modal('hide');
            event.target.click();
            $element.data('confirm', message); // Replace data-confirm (in case of AJAX update, still want prompt next time)
          }
        });

      $confirmModal.on('keypress', function (e) {
        if (e.keyCode == 13) {
          $okButton.trigger('click');
        }
      });

      $('.modal-title', $confirmModal).html(i18n.t('global.are_you_sure'));
      $('.modal-footer', $confirmModal).append($cancelButton);
      $('.modal-footer', $confirmModal).append($okButton);
      $('.modal-body', $confirmModal).html(message);

      $confirmModal.appendTo('body');
      $('.modal-header').show();
      $('.modal-footer').show();
      $confirmModal.modal('show');

      return false;
    });

    // Handler for remote modals
    $('.btn-login').on('click', function (ev) {
      ev.preventDefault();

      $('#genericModal .modal-body').load(ev.target.href, function () {
        if (!$(ev.target).data('title')) {
          $('#genericModal .modal-header').hide();
        } else {
          $('#genericModal .modal-title').html($(ev.target).data('title'));
          $('#genericModal .modal-header').show();
        }

        if (!$(ev.target).data('footer')) {
          $('#genericModal .modal-footer').hide();
        } else {
          $('#genericModal .modal-footer').html($(ev.target).data('footer'));
          $('#genericModal .modal-footer').show();
        }

        $('#genericModal').modal('show');
      });
    });

    let $flashModal = $('.modal.flash');

    if ($flashModal.length) {
      let locked = $('.close', $flashModal).length == 0;

      $flashModal.modal({
        keyboard: !locked,
        backdrop: locked ? 'static' : true
      });
      $flashModal.modal('show');
    }
  }
};
