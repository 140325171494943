adNotam.website.new_landing = {
  init: function () {
    $('.space_categories_list li').on('click', (ev) => {
      $('.space_categories_list li.active').removeClass('active');
      $(ev.target).addClass('active');

      const container = $(ev.target).parents('.popular_products');
      const targetUrl = '/popular_products';
      const space_category = $(ev.target).data('space_category');

      $.ajax({
        url: targetUrl,
        cache: false,
        contentType: false,
        data: {
          element_id: container.data('element-id'),
          space_category: space_category !== 'all' ? space_category : null
        }
      })
        .done(function (html) {
          $('.carousel-inner', container).html(html);

          if ($('.carousel-inner .carousel-item', container).length > 1) {
            $('.carousel-control-prev, .carousel-control-next', container).show();
          } else {
            $('.carousel-control-prev, .carousel-control-next', container).hide();
          }
        })
        .fail(function (err) {
          console.debug(err.responseText);
        });
    });
  }
};
